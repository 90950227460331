import React, { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import { BsCartPlus, BsPlus } from "react-icons/bs";
import { AiOutlineArrowRight, AiOutlineCheckCircle } from "react-icons/ai";
import firebase from "firebase/compat/app";
import firebaseConfig, { auth, db } from "../firebaseConfig";
import "firebase/compat/firestore";
import { Link, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Nav from "../component/Nav";
import Homes from "./Homes";
// import Son from "../Assets/seb.jpg";
// import MobileNav from "./MobileNav";
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import Shop from "./Shop";


const ProductDetails = () => {
  const { productId } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const currentUser = auth.currentUser;
  
   // fetch the user data from firebase
   useEffect(() => {
    const subscriber = db
        .collection('userss')
        .doc(currentUser?.uid)
        .onSnapshot(documentSnapshot => {
          setProfileData(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
  }, [currentUser]);

  const [productData, setProductData] = useState(null);
  const [Show, setShow] = useState(false);
  const { addItem } = useCart();
  const [addGlassFrame, setAddGlassFrame] = useState(false); // Toggle state for Glass Print Frame


  useEffect(() => {
    async function getProductData() {
      // Initialize Firebase
      const firebaseConfig = {
        apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
        authDomain: "agency-b5b65.firebaseapp.com",
        projectId: "agency-b5b65",
        storageBucket: "agency-b5b65.appspot.com",
        messagingSenderId: "30425467879",
        appId: "1:30425467879:web:aef3dadb4967b61c258b42",
        measurementId: "G-1QKVN1SZDF"
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      const db = firebase.firestore();
      const docRef = db.collection("Products").doc(productId);
      const doc = await docRef.get();

      if (doc.exists) {
        const data = doc.data();
        const colors =
          typeof data.color === "string" ? data.color.split(",") : [];
        setProductData({ id: doc.id, ...data, colors });
      } else {
        console.log("No such document!");
        setProductData(null);
      }
    }

    getProductData();
  }, [productId]);

 

  const stripePromise = loadStripe('pk_live_51QPVmXEIVgjcJ15kxwzbGH2petK09KDUX2Ib0Q4l1HuwcO4q3ogo8W43kvOFmsCO6XrjoRzyZNhfnqf47H6RSiHl00TQsQ0mMo');
  // const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

  
  const glassFramePrice = 150; // Additional price for Glass Print Frame
  const totalPrice = addGlassFrame
    ? productData?.price + glassFramePrice
    : productData?.price;

    const totalAmount = totalPrice
    const availableCredits = profileData?.balance

    const [selectedCredits, setSelectedCredits] = useState(0);
  const [result, setResult] = useState(null);

  const discountTiers = {
    500: 0.05, // 5% off
    1000: 0.10, // 10% off
    2000: 0.20, // 20% off
  };

  const applyDiscount = () => {
    if (!discountTiers[selectedCredits]) {
      setResult({
        error: "Invalid credit selection. Please choose 500, 1000, or 2000 credits.",
      });
      return;
    }

    if (selectedCredits > availableCredits) {
      setResult({
        error: "Insufficient credits. Please select a valid amount.",
      });
      return;
    }

    const discountRate = discountTiers[selectedCredits];
    const discount = totalAmount * discountRate;
    const discountedAmount = totalAmount - discount;

    setResult({
      discountedAmount,
      discount,
      message: `You have received a ${discountRate * 100}% discount.`,
    });
  };


    console.log("Oku", result?.discountedAmount)

    const options = {
      mode: 'payment',
      amount: result?.discountedAmount ? result?.discountedAmount : totalPrice,
      currency: 'usd',
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      },
    };

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div 
    // style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    className=" bg-[#ffffff] w-[100%] text-black pt-24">
      <div className="justify-content-center grid lg:grid-cols-2 mt-5">
        <div xs={10} md={7} lg={5} className="p-0 w-fit">
          {productData.product_image && productData.product_image.length > 0 ? (
            <Carousel showArrows={true} showThumbs={true}>
              {productData.product_image.map((Cloth, index) => (
                <div
                  key={index}
                  className="lg:w-fit  w-[90%] ml-[2%] lg:ml-[10%] border-2 border-white p-2 rounded-lg text-center"
                >
                  <img src={Cloth} alt={`Cloth ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          ) : (
            <div>No images available</div>
          )}
          {/* <div
          > 
            <AiOutlineArrowRight color="green" size={30} className="md:hidden my-7 ml-[45%]"/>
          </div> */}
        </div>
      {/* <div xs={10} md={7} lg={7} className="product-details lg:w-[30%] w-[100%] lg:mt-20 mt-0 gap-1 md:grid lg:right-20 lg:absolute"> */}
        <div className="lg:px-20 px-5 lg:mt-16 mt-0 ">
          <h1 className="text-2xl">{productData.title}</h1>
           <div className="text-black p-2">Price $: {totalPrice}</div>
          <div className="text-black mb-2 ">
          Quantity Left: {productData?.quantity}
          </div>
            {/* Toggle Glass Print Frame */}
            {
              productData?.price === 285 &&
            <div className="flex self-center justify-center items-center my-2">
            <button
              className={`py-2 px-4 text-sm rounded-full text-white ${
                addGlassFrame ? "bg-green-600" : "bg-[#555]"
              }`}
              onClick={() => setAddGlassFrame(!addGlassFrame)}
            >
              {addGlassFrame ? (
                <AiOutlineCheckCircle className="inline mr-0.5" />
              ) : (
                <BsPlus className="inline" />
              )}
              {addGlassFrame ? "Remove" : "Frame"}
            </button>
            <span className="ml-2 text-sm text-gray-600">
            Framed (Wood Frame - UV Plexiglass)
            </span>
          </div>
            }
          <span id="pay"></span>
          <div className="grid items-center justify-center">

          <button
            // onClick={() => addItem(productData)}
            onClick={() => addItem({ ...productData, price: totalPrice })}
            className="my-4 flex font-semibold lg:relative z-50 fixed bottom-14 lg:bottom-0 cursor-pointe w-80 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-3 px-6 bg-gray-50 text-black duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            {/* <BsCartPlus size="1.8rem" className="" /> */}
            Add to cart
          </button>

          <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md lg:mb-0 mb-5 space-y-4">
      <h1 className=" font-extrabold ">Apply Discount with your earnings</h1>
      <p className="text-gray-700 text-sm">Total Amount: <span className="font-semibold">${totalAmount}</span></p>
      <p className="text-gray-700 text-sm">Your Available Credits: <span className="font-semibold">{availableCredits}</span></p>

      <div className="space-y-2 text-sm">
        <label className="block text-gray-700 font-semibold">Select Credits:</label>
        <select
          className="w-full px-3 py-2 border text-sm rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          value={selectedCredits}
          onChange={(e) => setSelectedCredits(Number(e.target.value))}
        >
          <option value="0">Select Credits</option>
          <option value="500">500 Credits (5% Off)</option>
          <option value="1000">1000 Credits (10% Off)</option>
          <option value="2000">2000 Credits (20% Off)</option>
        </select>
      </div>

      <button
        className="w-full bg-blue-500 text-white py-2 px-4 text-sm rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        onClick={applyDiscount}
      >
        Apply Discount
      </button>

      {result && (
        <div className="mt-4 p-4 border rounded-md text-sm">
          {result.error ? (
            <p className="text-red-500">{result.error}</p>
          ) : (
            <div>
              <p className="text-green-500 font-semibold">{result.message}</p>
              <p>Discounted Amount: <span className="font-semibold">${result.discountedAmount.toFixed(2)}</span></p>
              <p>You Saved: <span className="font-semibold">${result.discount.toFixed(2)}</span></p>
            </div>
          )}
        </div>
      )}
    </div>
            

{
  Show ?
  <>
  <div className="m-5">
          <Elements stripe={stripePromise} options={options}>
    <CheckoutForm />
  </Elements>
  </div>
  </>
  :
            <a
            onClick={() => setShow(!Show)}
            href="#pay"
            className="my-4 flex text-sm cursor-pointer z-50 w-80 self-center lg:relative fixed bottom-0 lg:bottom-0 text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-gradient-to-r from-blue-600 to-yellow-600 text-white duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            Buy Now
          </a>
}

        
            {/* <img className="w-48 drop-shadow-2xl shadow-2xl mb-5 rounded-lg" src={"https://firebasestorage.googleapis.com/v0/b/nextberries-cb5b9.appspot.com/o/barcode%2FIMG_1446.jpg?alt=media&token=f2f60197-cdb1-4c3b-98e6-d480560b1c6e"} alt={"qr_code"} /> */}
              {/* <p className="font-bankGothic">scan me</p> */}
          </div>
          {/* <br />
          <div className="text-black p-3 mb-2 bg-slate-50 ">
          Price $: {productData.price}
          </div>
          <br /> */}
          {/* <strike className="text-red-300 font-extralight mt-3">
            $. {productData.old_price}
          </strike> */}
          <b className="h5 ">Review Chart 5.0 ⭐⭐⭐⭐⭐</b>
          <br />


          {/* <b className="h5 pb-10">L Unisex Size For All</b> */}
          {/* <br /> */}
          <b className="h5">Description:</b>
          <p
            className=" mb-5 text-sm"
            style={{ opacity: "0.8", fontWeight: "400" }}
          >
            {productData.descriprion}
          </p>
          {/* <p className="mt-3 mb-5 h5" style={{ opacity: "0.8", fontWeight: "400" }}>
            Colors:
            {productData.color.map((color, index) => (
              <span key={index}>
                {color.trim()}
                {index !== productData.product_color.length - 1 ? ", " : ""}
              </span>
            ))}
            {productData.product_color}
          </p> */}
          {/* <div
          // to={'/Customization'}
            onClick={() => alert('Coming Soon')}
            className="m-2 flex font-bold cursor-pointer text-center justify-center items-center hover:text-gray-50 hover:bg-gray-600 drop-shadow-2xl shadow-2xl py-3 px-0  bg-gray-50 text-black duration-500 rounded-lg"
            // className="bg-dark-primary mt-10 mb-10 p-2 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            Customize
          </div> */}
          {/* <p className="mt-3 h5" style={{ opacity: "0.8", fontWeight: "400" }}>
            ${productData.shipping_fee}
          </p> */}
         </div>
      {/* </div> */}
      </div>
      {/* <Nav />
      <MobileNav title={"me"}/> */}
      <Shop title={'me'} />
    </div>
  );
};

export default ProductDetails;


// import React, { useState, useEffect } from "react";
// import { useCart } from "react-use-cart";
// import { BsCartPlus } from "react-icons/bs";
// import { AiOutlineArrowRight, AiOutlineCheckCircle } from "react-icons/ai";
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import { useParams } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Shop from "./Shop";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";

// const ProductDetails = () => {
//   const { productId } = useParams();
//   const [productData, setProductData] = useState(null);
//   const [Show, setShow] = useState(false);
//   const [addGlassFrame, setAddGlassFrame] = useState(false); // Toggle state for Glass Print Frame
//   const { addItem } = useCart();

//   // Initialize Firebase and Fetch Product Data
//   useEffect(() => {
//     async function getProductData() {
//       const firebaseConfig = {
//         apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
//         authDomain: "agency-b5b65.firebaseapp.com",
//         projectId: "agency-b5b65",
//         storageBucket: "agency-b5b65.appspot.com",
//         messagingSenderId: "30425467879",
//         appId: "1:30425467879:web:aef3dadb4967b61c258b42",
//         measurementId: "G-1QKVN1SZDF",
//       };

//       if (!firebase.apps.length) {
//         firebase.initializeApp(firebaseConfig);
//       }

//       const db = firebase.firestore();
//       const docRef = db.collection("Products").doc(productId);
//       const doc = await docRef.get();

//       if (doc.exists) {
//         setProductData({ id: doc.id, ...doc.data() });
//       } else {
//         console.log("No such document!");
//         setProductData(null);
//       }
//     }

//     getProductData();
//   }, [productId]);

//   // Stripe Configuration
//   const stripePromise = loadStripe("pk_live_51QPVmXEIVgjcJ15kxwzbGH2petK09KDUX2Ib0Q4l1HuwcO4q3ogo8W43kvOFmsCO6XrjoRzyZNhfnqf47H6RSiHl00TQsQ0mMo");

//   const glassFramePrice = 50; // Additional price for Glass Print Frame
//   const totalPrice = addGlassFrame
//     ? productData?.price + glassFramePrice
//     : productData?.price;

//   if (!productData) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="bg-[#ffffff] w-[100%] text-black pt-24">
//       <div className="grid lg:grid-cols-2 mt-5">
//         {/* Product Image Carousel */}
//         <div className="p-0 w-fit">
//           {productData.product_image && productData.product_image.length > 0 ? (
//             <Carousel showArrows={true} showThumbs={true}>
//               {productData.product_image.map((Cloth, index) => (
//                 <div
//                   key={index}
//                   className="lg:w-fit w-[90%] ml-[2%] lg:ml-[10%] border-2 border-white p-2 rounded-lg text-center"
//                 >
//                   <img src={Cloth} alt={`Cloth ${index + 1}`} />
//                 </div>
//               ))}
//             </Carousel>
//           ) : (
//             <div>No images available</div>
//           )}
//         </div>

//         {/* Product Details Section */}
//         <div className="px-20 lg:mt-16 mt-0">
//           <h1 className="text-2xl font-bold">{productData.title}</h1>
//           <div className="text-black p-2">Price $: {totalPrice}</div>
//           <div className="text-black mb-2">Quantity Left: {productData.quantity}</div>

//           {/* Description */}
//           <div className="my-4">
//             <h2 className="font-semibold text-lg">Description:</h2>
//             <p className="text-sm text-gray-700">{productData.description || "No description available."}</p>
//           </div>

//           {/* Toggle Glass Print Frame */}
//           <div className="flex items-center my-4">
//             <button
//               className={`py-2 px-4 rounded-full text-white ${
//                 addGlassFrame ? "bg-green-600" : "bg-gray-600"
//               }`}
//               onClick={() => setAddGlassFrame(!addGlassFrame)}
//             >
//               {addGlassFrame ? (
//                 <AiOutlineCheckCircle className="inline mr-2" />
//               ) : (
//                 <BsCartPlus className="inline mr-2" />
//               )}
//               {addGlassFrame ? "Remove Glass Print Frame" : "Add Glass Print Frame"}
//             </button>
//             <span className="ml-4 text-sm text-gray-600">
//               Extra $50 for Glass Print Frame
//             </span>
//           </div>

//           {/* Add to Cart Button */}
//           <button
//             onClick={() => addItem({ ...productData, price: totalPrice })}
//             className="my-4 w-full py-3 px-6 bg-gray-50 text-black hover:bg-gray-400 rounded-full shadow-xl"
//           >
//             Add to Cart
//           </button>

//           {/* Buy Now Button */}
//           {Show ? (
//             <div className="m-5">
//               <Elements stripe={stripePromise}>
//                 <CheckoutForm />
//               </Elements>
//             </div>
//           ) : (
//             <button
//               onClick={() => setShow(true)}
//               className="my-4 w-full py-3 px-6 bg-black text-white hover:bg-gray-800 rounded-full shadow-xl"
//             >
//               Buy Now
//             </button>
//           )}
//         </div>
//       </div>
//       <Shop title={"me"} />
//     </div>
//   );
// };

// export default ProductDetails;
