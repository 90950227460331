import { useEffect, useState } from "react";
import { collection, query, orderBy, limit, onSnapshot } from "firebase/firestore";
import { db, storage, auth } from "../firebaseConfig";
import { Link } from "react-router-dom";
import Leaderboard from "./Leaderboard";
import RewardsPage from "./RewardsPage";

function Rewards() {
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const currentUser = auth.currentUser;

    const [active, setActive] = useState("Activity");

    const handleClick = (link) => {
        if(link !== 'Activity'){
            alert("you have no friends yet")
        }
      setActive(link);
    };

    useEffect(() => {
      const usersCollectionRef = collection(db, "userss");
  
      // Query to get top 3 users based on balance in descending order
      const topUsersQuery = query(usersCollectionRef, orderBy("balance", "desc"));
  
      const unsubscribe = onSnapshot(topUsersQuery, (snapshot) => {
        const topUsers = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLeaderboardData(topUsers);
      });
  
      return () => unsubscribe();
    }, []);

    
   // fetch the user data from firebase
   useEffect(() => {
    const subscriber = db
        .collection('userss')
        .doc(currentUser?.uid)
        .onSnapshot(documentSnapshot => {
          setProfileData(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
  }, [currentUser]);

  return (
    <div className="dark:bg-gradient-to-r from-black to-yellow-900 bg-white pt-20 lg:pt-24 dark:text-white text-black h-full flex flex-col">
      {/* Header */}
      <div className=" justify-between items-center text-start lg:pl-20">
      <div className="flex justify-between items-center text-start p-4 ">
        <div className="flex items-center">
        <div className="flex justify-center items-center mr-2">
          {/* <p className="font-semibold m-2 text-sm dark:text-[#ccc]">{`Token balance ${profileData.balance}`}  </p> */}
          {profileData?.profileImage ? (
            <img
            src={profileData?.profileImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
              {profileData?.username[0]?.toUpperCase()}
            </div>
          )}
        </div>
                  <div className="lg:flex">
            <div className="grid">
            <h3 className="text-xl">{profileData?.username}</h3>
            <p className="text-sm mb-1">29 connections</p>
            </div>

            <div className="grid lg:ml-2">

          <a
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            href={"#leader"}
            className="bg-gradient-to-r from-blue-600 to-yellow-600 border-none px-4 text-sm p-1 mb-1 text-center rounded-lg text-white"
            >
            View Leaderboard
          </a>
          <a
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            href={"#rewards"}
            className="bg-gradient-to-r from-blue-600 to-yellow-600 border-none px-4 text-sm p-1 text-center rounded-lg text-white"
            >
            Claim Reward
          </a>
              </div>
          </div>
        </div>
    
      </div>
      <div className="flex mb-2 space-x-4 border-b-2 border-gray-300">
      {["Activity", "Friends", "Friend Requests"].map((link) => (
        <a
          key={link}
          className={`pb-2 ${
            active === link
              ? "border-b-2 px-8 dark:border-white border-gray-600"
              : "text-gray-600 hover:text-gray-800"
          }`}
          onClick={() => handleClick(link)}
          style={{ cursor: "pointer" }}
        >
          {link}
        </a>
      ))}
    </div>
      </div>

      {/* Activity Feed */}
      <div className="flex-1 p-4  lg:pl-20 overflow-y-auto">

        {leaderboardData.map((user, index) => (
            <div
              key={user.id}
            >
              <div className="flex items-center space-x-2 p-2 lg:ml-3">
                <img
                  src={user?.profileImage || "https://via.placeholder.com/50"}
                  alt={`${user?.username || "User"}'s Avatar`}
                  className="w-6 h-6 rounded-full border-2 border-white"
                />
                  <span className="">You shared details with {user?.username}</span><span className="text-sm"> 06:29pm</span>
              </div>
            </div>
          ))}

      </div>

      <span id="leader"></span>
<div className="items-center justify-center flex">
      {/* Navigation */}
      <Leaderboard />
</div>
      <span id="rewards"></span>
      <RewardsPage />
      
    </div>
  );
}

export default Rewards;