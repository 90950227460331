export const Navlinks = [
    {
      id: 1,
      name: "Home",
      link: "/",
    },
    {
      id: 2,
      name: "About",
      link: "/#about",
    },
    {
      id: 3,
      name: "Shop",
      link: "/shop",
    },
    {
      id: 4,
      name: "Redeem Rewards",
      link: "/rewardspage",
    },
    {
      id: 5,
      name: "Leaderboard",
      link: "/#Leaderboard",
    },
    // {
    //   id: 4,
    //   name: "REVIEW",
    //   link: "/#boo",
    // },
  ];