import React, { useState, useEffect, useRef } from "react";
import item from "./Asset/88.png"
// import item from "./Asset/code.webp"
import vid from "./Asset/read.mp4"
import vid1 from "./Asset/vfa.mp4"
import mvid from "./Asset/vfa.mp4"
import st from "./Asset/st.mp4"
import gbd from "./Asset/art.jpg"
import v from "./Asset/v.mp3"
import { useNavigate } from "react-router-dom";
import { FaUserFriends, FaStore, FaChartBar, FaQrcode, FaVolumeUp, FaVolumeMute, } from 'react-icons/fa';
import { IoColorPalette, IoExtensionPuzzleOutline } from "react-icons/io5";
import Contact from "./Contact/Contact";
import Part from "./Paterners/Part";
import AppStoreBanner from "./AppStoreBanner/AppStoreBanner";
import { SlArrowDown } from "react-icons/sl";
import Shop from "./Shop";
import firebase from "../firebaseConfig";
import ScrollToTop from "./ScrollToTop";
import Leaderboard from "./Leaderboard";
import Cookies from "../Cookies";
import Form from "./Form";



const Hero = ({ }) => {
    const [inputValue, setInputValue] = useState("");
    const [Read, setRead] = useState(false);
    const navigate = useNavigate();

    const [theme, setTheme] = useState(
      localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
      // localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
    );
// console.log(theme)

    const currentURL = `${window.location.origin}/`;


  const handleClaimLinktree = () => {
    // Pass the input value to the Register component
    navigate(user ? '/profile' : "/register", { state: { username: inputValue } });
    window.scrollTo(0, 0)
  };

  
  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  useEffect(() => {
    
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);


  const FeatureCard = ({ Icon, title, description }) => (
    <div className="bg-white dark:bg-gray-600 text-start shadow-lg rounded-lg p-6 lg:w-96 mx-auto ">
      <div className="flex items-center justify-center w-12 h-12 rounded-full mx-auto mb-4">
        <Icon className="text-purple-600 text-8xl" />
      </div>
      <h3 className="text-3xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  );

  const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    const togglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset audio to the start when stopped
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

  return (
    <div className="dark:bg-gradient-to-r from-black to-yellow-900 bg-white min-h-screen flex w-[100%] flex-col items-center text-center">
      

  {/* <div
  // style={{width: '100%'}}
  className="dark:hidden w-[100%] hidden lg:inline h-[100%] justify-center items-center object-cover"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${vid} type="video/mp4" />
    Your browser does not support the video tag.
    </video>`
  }}
  // <source src=${"https://web-video-resource.gentlemonster.com/assets/stories/jentle-salon/teasing/main/video-src-pc.mp4"} type="video/mp4" />
/>  */}

<div
  // style={{width: '100%'}}
  className="w-[100%]  h-[100%] hidden lg:inline justify-center items-center object-cover"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${vid1} type="video/mp4" />
    Your browser does not support the video tag.
    </video>`
  }}
  // <source src=${"https://web-video-resource.gentlemonster.com/assets/stories/jentle-salon/teasing/main/video-src-pc.mp4"} type="video/mp4" />
/> 


{/* <div
  // style={{width: '100%'}}
  className="hidden lg:flex w-[100%] h-[100%] justify-center items-center object-cover absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    height: 100%;
    left: 0px;
    right: 0px;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${"https://web-video-resource.gentlemonster.com/assets/stories/jentle-salon/teasing/main/video-src-pc.mp4"} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/>  */}
   
   {/* Mobile */}
<div
  // style={{width: '100%'}}
  className="lg:hidden w-[100%] h-[130%] flex justify-center items-center object-cover absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${mvid} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/>   
{/* <div
  // style={{width: '100%'}}
  className="lg:hidden w-[100%] dark:hidden h-[130%] flex justify-center items-center object-cover absolute"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${vmo} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/>    */}


      {/* Main Content */}
      <div className="lg:hidden pt-[190%] flex" />

      <div style={{ display: "flex", alignItems: "center" }} className="text-white">
            {/* Hidden Audio Element */}
            <audio ref={audioRef} src={v} loop />

            {/* Mute/Unmute Button */}
            <button
                onClick={togglePlay}
                style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "24px",
                }}
                className="absolute z-50 lg:top-32 top-28 lg:right-40 right-5"
            >
                {isPlaying ? <FaVolumeUp />  : <FaVolumeMute />}
            </button>
        </div>
      <div className=" flex flex-col lg:flex-row gap-10 text-start self-center absolute lg:mt-28 mt-48">
      <div className=" align-middle">
          <div 
          className="text-white p-8 rounded-2xl text-center shawdow-2xl max-w-lg"
          // className="text-white lg:bg-[rgba(52,52,52,0.3)] mt-16 dark:bg-[rgba(52,52,52,0.3)] p-8 rounded-2xl text-center shawdow-2xl max-w-lg"
          >
            <p className="mt-0 font-bold text-6xl lg:text-8xl">Vibe Fine Arts </p>
            <p className="mt-3 text-3xl lg:text-5xl text-center">Wear-to-Earn<span className="text-[#aaa]">™</span></p>
            {/* <p className="text-yellow-400 ml-2 mr-5 mt-6 max-w-md md:text-base">
              Tech-Forward Sloutions for the Modern Brand
              Join a Tech-Forward Future Just One Chip and One link you share everything you create, 
              curate and sell from your Instagram, TikTok, Twitter, YouTube and other social media profiles.
            </p> */}
          </div>

          <div 
  className="text-white flex self-center items-center justify-center text-[60px] animate-bounce"
>
  <SlArrowDown />
</div>

      
         
      <div className="mt-2 mb-10 flex flex-col items-center">

        {/* <input
        value={`${currentURL}${inputValue}`}
        onChange={(e) => setInputValue(e.target.value.replace(currentURL, ""))}
          type="text"
          placeholder="linktr.ee/yourname"
          className="px-4 font-semibold mb-2 py-3 outline-blue-400 shadow-md h-14 rounded-2xl bg-[rgba(255,255,255,0.6)] dark:bg-[rgba(52,52,52,0.3)] text-gray-600 dark:text-gray-50  placeholder-gray-600  w-[90%] "
          /> */}
        {/* <button onClick={handleClaimLinktree} className="hidden dark:inline px-12 font-semibold shadow-md py-2 outline-none bg-gradient-to-r from-blue-600 to-yellow-600 text-white rounded-full h-12 lg:h-14">
          Get Started
          </button> */}
       <button onClick={handleClaimLinktree} className="px-12 font-semibold shadow-md py-2 outline-none bg-gradient-to-r from-blue-600 to-yellow-600 text-white rounded-full h-12 lg:h-14">
        {/* <button onClick={handleClaimLinktree} className="inline dark:hidden px-12 font-semibold shadow-md py-2 outline-none bg-blue-600 text-[#fff] rounded-full h-12 lg:h-14">         */}

          Start Earning
        </button>
      </div>

      </div>

      {/* <div className=" self-center items-center text-center lg:-mt-0 -mt-16 "> */}
            {/* <img src={"https://cdn.newtechstore.eu/wp-content/uploads/2024/07/JAKCOM-N3-Smart-Nail-Chip.jpg"} className="rounded-full shadow-lg inline self-center" alt="" /> */}
            {/* <img src={gbk} className="lg:w-[60%] drop-shadow-2xl inline self-center" alt="" /> */}
            {/* <img src={nail} className=" drop-shadow-2xl inline self-center" alt="" /> */}
      {/* </div> */}
     </div>

      {/* Input Field */}

      <div
  // style={{width: '100%'}}
  className="w-[100%] h-[100%] justify-center items-center flex object-cover"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
        position: relative;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${st} type="video/mp4" />
    Your browser does not support the video tag.
    </video>`
  }}
  // <source src=${"https://web-video-resource.gentlemonster.com/assets/stories/jentle-salon/teasing/main/video-src-pc.mp4"} type="video/mp4" />
/>

      
<span id="about"></span>
            <div className="dark:bg-gradient-to-r from-black to-yellow-900 w-[100%] pb-0 lg:py-0">
              {/* <div className="bg-[#780016] w-[100%] pb-10 lg:py-20"> */}
              
              {/* <div className="bg-white text-center justify-center shadow-lg self-center flex justify-self-center -mt-[30%] lg:-mt-[10%] lg:w-[60%] h-60 w-[80%] lg:h-64">
                        
                          <h1 className="text-center p-2 font-bold text-gray-600">PARTNERS</h1>
  
                </div> */}
                
                <div className="flex flex-col lg:mt-0 lg:flex-row justify-center items-center text-center mx-4 align-middle">
  
                  <div className="dark:text-[#fff] text-[#000] font-bold text-start lg:ml-10 align-middle p-2">
                      <p className="mt-5 text-2xl font-bold lg:text-5xl">
                      Vibe Fine Arts is redefining Art, Culture & Fashion.
                      </p>
                      
                      <p className="dark:text-[#fff] text-[#000] mt-6 max-w-md font-normal ">
                      Vibe Fine Arts is excited to present Zheyi Zhang’s "Here & There" experience, featuring his captivating Metrocard artworks. Through our Wear-to-Earn program, powered by New York Culture Club, you can earn exclusive rewards by sharing the art and your story with friends.                       {/* The brand embodies the spirit of freedom and self-expression and empowering users to connect digitally through integrated NFC technology. The mission is to transform apparel into dynamic platforms, bridging physical and digital worlds. Vibe Fine Arts invites users to join the movement, embracing style and technology to open doors to new opportunities */}
                                          </p>
                      <button
                       className="bg-black dark:bg-gradient-to-r from-blue-600 to-yellow-600 shadow-xl px-10 p-4 mx-3 mt-5 rounded-full text-white hover:scale-110 duration-500 font-bold">Wear to Earn</button>
                   </div>
  
                  <div className="hidden lg:inline self-center items-center lg:mt-0 mt-20 text-center">
                      <img src={item} className="rounded-md self-center animate-slowBounce" alt="" />
                  </div>
  
                </div>
  
              </div>

        

            


     


{/* <AppStoreBanner /> */}


     {/* <div className=" w-[100%] ">
              
                
                <div className="flex flex-col lg:flex-row justify-center self-center items-center text-center gap-10 mx-4 my-10 align-middle">
  
                  <div className=" self-center justify-center items-center text-center">
                      
<img src={m2} className=" " alt="Dark Mode Logo" />

                  </div>
  
                  <div className=" self-center justify-center items-center text-center">
               
                        <img src={m1} className=" " alt="Light Mode Logo" />
                
                   </div>
  
                </div>
  
              </div>

               */}


         






            <div className=" w-[100%] pb-10 lg:-mt-0 -mt-6">
            {/* <div className="bg-[#e9c0e9] w-[100%] pb-10 lg:py-20"> */}
              
            {/* <div className="bg-white text-center justify-center shadow-lg self-center flex justify-self-center -mt-[30%] lg:-mt-[10%] lg:w-[60%] h-60 w-[80%] lg:h-64">
                      
                        <h1 className="text-center p-2 font-bold text-gray-600">PARTNERS</h1>

              </div> */}
              
              <div className="flex flex-col justify-center items-center text-center mx-3 mt-10 align-middle">

                <div className=" lg:self-center self-start flex lg:justify-center px-4  lg:mt-0 lg:items-center text-center">
                    {/* <img src={cloth} className="rounded-md self-center" alt="" /> */}

                    {/* <img src={gbk} className="rounded-2xl lg:w-[40%]" alt="Light Mode Logo" /> */}
                    {/* <img src={gbh} className="rounded-2xl lg:w-[40%]" alt="Light Mode Logo" /> */}
                    <img src={gbd} className="rounded-2xl lg:w-[100%]" alt="Light Mode Logo" />
                    {/* <img src={gb} className="rounded-2xl lg:w-[50%] " alt="Light Mode Logo" /> */}
                    {/* <img src={gb} className="rounded-2xl " alt="Light Mode Logo" /> */}
                </div>

                <div className="dark:text-white lg:px-40 font-bold text-start align-middle px-4 mt-5">
                    <p className=" text-2xl font-bold lg:text-4xl">Artist Biography</p>
                    <p className="mt-3 font-normal mb-3"> Zheyi Zhang is a multidisciplinary artist whose work has evolved over two decades, consistently exploring themes of self-identity and creative expression. His practice spans oil paintings, oil pastels, sculptures, and large public installations, often incorporating logical clues and intersecting artistic domains to fuel his ongoing creativity.
                      <a className="text-blue-600" onClick={() => setRead(!Read)}>
          {Read ? "hide" : "read more"}
        </a></p>
                    {
                      Read &&
                      <>
                      <p className=" font-normal "> Since relocating to New York in 2022, Zhang has been inspired by the city's energy, especially its subway system, which became the backdrop for a series of Metrocard paintings—intimate visual diaries capturing the everyday life of the city. This process of painting directly on Metrocards allowed Zhang to simplify and relax into his creativity, while retaining a detached yet insightful perspective on the world around him. </p>
                    <p className="mt-3 mb-5 font-normal "> In addition to his Metrocard works, Zhang’s daily interactions with the U.S. postal system sparked new ideas, leading him to use mailed advertisements and transparent envelope films as canvases. These works explore the notion of the "Unseen Window," a metaphorical space that allows him to engage with the world while maintaining a sense of distance and introspection.  </p>
                      </>
                    }
                    {/* <p className="text-[#502274] ml-2 mt-6 max-w-md md:text-base">
                      Book a FREE consultation with our Team today!
                    </p> */}
                    <iframe src="https://drive.google.com/file/d/1QNgRSYX2lK-T8CnERp17aTnzl-ykz_7o/preview" className="w-full mb-10 lg:h-[480px] rounded-lg shadow-lg" ></iframe>
                    {/* <iframe src="https://drive.google.com/file/d/1QNgRSYX2lK-T8CnERp17aTnzl-ykz_7o/preview" className="w-full mb-10 lg:h-[480px]" allow="autoplay; encrypted-media" ></iframe> */}
                    <a href="https://www.zheyizhang-art.com" className="bg-black dark:bg-gradient-to-r from-blue-600 to-yellow-600 shadow-lg px-10 p-4 rounded-full text-white hover:scale-110 duration-500 font-bold">Learn More</a>
                 </div>

              </div>

            </div>

<div className="dark:text-white  w-[100%]">
<h2 className="text-2xl font-bold ">Get Started</h2>
<p className="px-5  text-sm">Simply by tapping the V-Train logo on your garment</p>
<p className=" text-sm mb-5 text-gray-500">Make Friends. Earn Points. Claim Rewards.</p>

<div
  // style={{width: '100%'}}
  className="w-[100%] h-[100%] justify-center items-center flex object-cover"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
        position: relative;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${vid} type="video/mp4" />
    Your browser does not support the video tag.
    </video>`
  }}
  // <source src=${"https://web-video-resource.gentlemonster.com/assets/stories/jentle-salon/teasing/main/video-src-pc.mp4"} type="video/mp4" />
/>

  </div>
              <Shop title={'me'} />

              <Leaderboard />

         


<div className=" w-[100%]">

          <Contact />
            {/* <Part/> */}
</div>
     {/* <Part /> */}

     {/* <Form title={'me'}/> */}


         

        {/* <div className="bg-gray-50 dark:bg-gray-700 dark:text-white w-[100%] py-12 text-center items-center px-6">

          <h1 className="lg:text-6xl text-4xl font-bold text-center p-2">You never have to change <br className=" hidden lg:inline" /> the link in your bio again</h1>
          <p className="mb-20 text-start lg:text-center text-md p-5">Back in 2016 Digital Twin solved social media’s most annoying problem: only having one link in your bio. <br />
             Culture Club was the first ‘link in bio’ platform made to solve this problem. <br /> NYCC has since become so much more. 
             Allowing businesses or creators to get more out of their social media, <br /> grow their following, 
            easily take payments and take back control of how your content is discovered.</p>
          <div className="max-w- text-start mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            <FeatureCard
              Icon={FaUserFriends}
              title="Grow your followers across all your social platforms"
              description="Give your followers easy access to all of your content in one simple link. Now everything you do is just one tap away!"
            />
            <FeatureCard
              Icon={FaStore}
              title="Sell products & monetize your audience"
              description="Sell your products, take payments, and even include 'support me' links to let your audience support, tip or donate."
            />
            <FeatureCard
              Icon={FaQrcode}
              title="Bring your offline world online with QR codes"
              description="Easily link from IRL – your product packaging, posters and flyers – to your online world with a simple QR code."
            />
          </div>

           

          <div className="max-w- mt-10 text-start mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
            <FeatureCard
              Icon={IoColorPalette}
              title="Grow your followers across all your social platforms"
              description="Give your followers easy access to all of your content in one simple link. Now everything you do is just one tap away!"
            />
            <FeatureCard
              Icon={FaQrcode}
              title="Bring your offline world online with QR codes"
              description="Easily link from IRL – your product packaging, posters and flyers – to your online world with a simple QR code."
            />
            <FeatureCard
              Icon={IoExtensionPuzzleOutline}
              title="Integrate your Digital Twin with your existing tech"
              description="Digital Twin seamlessly connects to other platforms like Mailchimp, Vimeo, Zapier, Amazon, YouTube, Google Analytics, plus more!"
            />
          </div>
        </div> */}  

              {/* <ScrollToTop /> */}
              <div className='hidden lg:inline'>
      <Cookies />
      </div>
              
                
    </div>
  );
};

export default Hero;
