import React, { useState } from "react";
import { useCart } from "react-use-cart";
import { MdOutlineShoppingCart } from "react-icons/md";
import PaystackForm from "./PaystackForm";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// import Nav from "../component/Nav";
import Homes from "./Homes";
import { Link, useNavigate } from "react-router-dom";
// import Son from "../Assets/seb.jpg";
// import MobileNav from "./MobileNav";
import { BiShoppingBag } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Shop from "./Shop";


const firebaseConfig = {
  apiKey: "AIzaSyAvfSZiORsVohwIB696oyJYwxBcuh-jRRY",
  authDomain: "markers-digital-pro.firebaseapp.com",
  projectId: "markers-digital-pro",
  storageBucket: "markers-digital-pro.appspot.com",
  messagingSenderId: "99311584893",
  appId: "1:99311584893:web:63167d001bb5c569f31314",
  measurementId: "G-H43QCYF1XV"
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

const Cart = ({title}) => {
  const {
    isEmpty,
    items,
    cartTotal,
    totalItems,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();



  const [productQuantities, setProductQuantities] = useState({});

  const handlePaymentSuccess = (response, userData) => {
    console.log("Payment successful:", response);

    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Successful'

    };

    firestore
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Payments")
      .add(data)
      .then((docRef) => {
        console.log("Payment data saved with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error saving payment data:", error);
      });

      // navigate()

      
    };
    
    // const navigate = <Link to={'/order'} />

  const handlePaymentClose = () => {
    console.log("Payment cancelled");
  };


  const handleOrderAttempts = (response, userData) => {
    const payeeName = userData.name;
    const payeeLocation = userData.location;
    const payeeEmail = userData.email;
    const payeePostalCode = userData.postalCode;
    const payeePhoneNumber = userData.phoneNumber;

    const productsInCart = items.map((item) => ({
      id: item.id,
      name: item.title,
      quantity: productQuantities[item.id] || item.quantity,
    }));

    const data = {
      amount: cartTotal,
      createdAt: new Date(),
      productPaidFor: items.map((item) => item.title).join(", "),
      product_image: items.map((item) => item.product_image).join(", "),
      quantity: items.reduce((total, item) => total + item.quantity, 0),
      payeeName,
      payeeLocation,
      payeeEmail,
      payeePostalCode,
      payeePhoneNumber,
      productsInCart,
      status: 'Attempt'
    };
    firestore
    .collection("Users")
    .doc(firebase.auth().currentUser.uid)
    .collection("OrderAttempt")
    .add(data)
    .then((docRef) => {
      console.log("Payment data saved with ID:", docRef.id);
    })
    .catch((error) => {
      console.error("Error saving payment data:", error);
    }); 
   };

   const [Show, setShow] = useState(false)

   
  const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

  const options = {
    mode: 'payment',
    amount: cartTotal,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };
  

  return (
    <>
    {/* {
      Show ?
      <Link 
      onClick={() => setShow(!Show)}
      // to="/cart"
       className="mr-4">
      <BiShoppingBag
        // size="30"
        className="h-6 w-6"
        color={title === "me" ? "#fff" : "#555"}
        // className="rounded-full shadow-2xl drop-shadow-2xl shadow-gray-700 "
      />
      {!isEmpty && (
        <span className=" absolute top-2 ml-4 font-bold text-lg text-white rounded-full w-7 text-center bg-orange-600">
          {totalItems}
        </span>
      )}
    </Link>
    : */}
    <div 
        // style={{backgroundImage: `url(${Son})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
    className="w-[100%] pt-5 px-0 grid justify-center bg-[#fff]">

{/* <Link
            className=" z-50 absolute top-5 right-5"
            onClick={() => setShow(!Show)}
          >

              <AiOutlineClose className="h-5 w-5 z-50" color="#000" />

          </Link> */}

    {/* className="w-screen grid justify-center text-center pt-10 lg:pt-16 bg-[#656564]"> */}
      {/* <h1 className="text-gray-700 text-lg text-start mt-5">
        Your order
        </h1> */}
      <h1 className="text-gray-700 mt-20 text-2xl">{isEmpty ? "Your Cart is Empty" : ""}</h1>
        <h1 className="text-gray-700 text-2xl px-10 text-start mb-5">
          Shopping Cart
        </h1>
      <div className="justify-content-center">
        <div
          responsive="sm"
          striped
          bordered
          hover
          className="mb-0 rounded-md p-2"
        >
          <tbody className="grid justify-center">
            {items.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="lg:flex p-4 px-10 w-80 md:w-fit bg-white rounded-md mb-14 justify-center items-center text-center grid grid-cols-1"
                >
                  <td className=" grid justify-center">
                    <div
                      className="rounded-lg ml- md:flex flex-col text-center justify-center mb-0 drop-shadow-2xl shadow-2xl"
                      style={{
                        background: "white",
                        height: "10rem",
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                        <img
                          src={item.product_image}
                          className="w-40 p-0 md:ml-0 text-center hover:scale-110 duration-500"
                          alt={item.title}
                        />
                    </div>
                  </td>
                  <td>
                    <h6 className="md:pl-10 text-center w-80 justify-center grid mt-8 pr-10 font-bold text-gray-800">
                      {item.title}
                  <td className="text-green-500 -10">$ {item.price}</td>
                    </h6>
                  </td>

                  <td className="">
                    <div className="flex text-center justify-center">
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity - 1)
                      }
                      className="text-3xl m-4 p-2 w-14 bg-white rounded-full drop-shadow-2xl shadow-2xl hover:bg-green-600 hover:text-white duration-500  text-green-600"
                      >
                      -
                    </button>
                      <button className="text-gray-600 p-2 w-14 duration-500 m-4 text-2xl rounded-full">{item.quantity}</button>
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity + 1)
                      }
                      className="text-3xl m-4 p-2 w-14 bg-white rounded-full drop-shadow-2xl shadow-2xl hover:bg-green-600 hover:text-white duration-500  text-green-600"
                      >
                      +
                    </button>{" "}
                      </div>
                      {/* <div className="flex items-center justify-center">
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity - 1)
                      }
                      className="text-4xl p-5 m-4 bg-white rounded-full duration-500 drop-shadow-2xl shadow-2xl hover:bg-red-600 hover:text-white w-20 text-red-600"
                    >
                      -
                    </button>
                    <p className="w-20 bg-white shadow-2xl drop-shadow-2xl rounded-full p-5">
                      {item.quantity}
                      </p>
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity + 1)
                      }
                      className="text-4xl m-4 p-5 w-20 bg-white rounded-full drop-shadow-2xl shadow-2xl hover:bg-green-600 hover:text-white duration-500  text-green-600"
                    >
                      +
                    </button>
                  </div> */}
                    <br className="md:hidden" />
                    <button
                      variant="danger"
                      onClick={() => removeItem(item.id)}
                      className="m-2 text-red-600 bg-white rounded-lg hover:text-white hover:bg-red-600 duration-700 p-2 px-5"
                    >
                      Remove Item
                    </button>
                  </td>
                  {/* <td className="text-yellow-500 items-baseline font-extralight lg:ml-5 mt-5">
                    Free Shipping
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </div>

        {!isEmpty && (
          <div
            // style={{ }}
            className="bg-light-black text-light mb-0"
          >
            <h2 className="py-2 text-2xl text-gray-700 ml-8 text-center font-bold">
              Total Price: $ {cartTotal}
            </h2>
            
            <div className="p-2 flex justify-center" md={4}>
              {/* <button
                variant="danger"
                className="m-2 text-red-600 font-bold text-center border-2 border-red-500 p-1 hover:bg-red-500 hover:text-white duration-500 rounded-lg"
                onClick={() => emptyCart()}
              >
                <MdOutlineShoppingCart size="1.7rem" className="ml-8" />
                Clear Cart
              </button> */}
                 <div
                variant="danger"
                className="my-4 flex font-semibold cursor-pointe w-[95%] ml-2.5 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-red-500 cursor-pointer drop-shadow-2xl shadow-2xl py-3 px-6 bg-gray-50 text-black duration-500 rounded-full"
                onClick={() => emptyCart()}
              >
                {/* <MdOutlineShoppingCart size="1.7rem" className="mr-2" />  */}
                 Clear Cart
              </div>

              <br />

              {/* <button
                variant="success"
              >
                <PaystackForm

                  amount={cartTotal} // Use the cart total as the payment amount
                  email={firebase?.auth()?.currentUser?.email} // Replace with the customer's email
                  onSuccess={handlePaymentSuccess}
                  onClose={handlePaymentClose}
                  handleOrderAttempts={handleOrderAttempts}
                />
              </button> */}

</div>

<div className="p-2 flex justify-center" md={4}>

{
  Show ?
  <>
          <span id="pay"></span>
  <div className="m-5">
          <Elements stripe={stripePromise} options={options}>
    <CheckoutForm />
  </Elements>
  </div>
  </>
  :
  <a
  onClick={() => setShow(!Show)}
  href="#pay"
            className="my-4 flex text-sm cursor-pointer z-50 w-[95%] self-center lg:relative fixed bottom-0 lg:bottom-0 text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-gradient-to-r from-blue-600 to-yellow-600 text-white duration-500 rounded-full"
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            Buy Now
          </a>
}
          </div>
          </div>
        )}

{/* <div className=" mt-[20%]  w-full justify-center items-center">
            <h1 className=" text-center text-xl md:font-bold font-bold mb-6 mt-2 text-white">
              &copy; Nextberries 2023. All rights reserved <Link className='hover:text-blue-500' to={'/policy'}>Privacy Policy</Link>.
            </h1>
          </div> */}
      </div>
      {/* <Nav />
      <MobileNav  title={"me"} /> */}
         {/* <div className="bg-gray-900">
          <Homes />
        </div> */}
        <Shop title={"me"} />
    </div>
    {/* } */}
    </>
  );
};

export default Cart;
