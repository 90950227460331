import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import son from "./Asset/80.png"


const Form = ({title}) => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    no: "",
    date: new Date,
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      // Check if all required fields are filled
  if (!form.name || !form.email || !form.no || !form.date || !form.message) {
    alert("Please fill in all required fields before submitting.");
    return; // Stop the submission if any field is empty
  }

    setLoading(true);

    emailjs
      .send(
        'service_k7bwn9y',
        'template_kcy8lc8',
        {
          to_name: "VFA website partnership form",
          from_name: form.name,
          from_email: form.email,
          from_no: form.no,
          from_date: form.date,
          from_address: form.message,
          to_email: "brian@vibefinearts.com",
          // to_email: "okuakpabio0@gmail.com",
          // to_email: "lukaprivatemail222@gmail.com",
        },
        'H0F05oQHMtdRTwuAo'
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. we'll get back to you as soon as possible.");

          setForm({
            name: "",
            email: "",
            message: "",
            no: "",
            date: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  const [show, setShows] = useState(false);


  return (
    <div
    //   className={`xl:mt-12 flex xl:flex-row flex-col-reverse gap-10 overflow-hidden`}
    >
      <motion.div
        className={`bg-white dark:bg-transparent p-8 ${title ? "pt-0" : "pt-28"}`}
        // className='flex-[0.75] bg-gray-600 p-8 rounded-2xl'
        
      >
        <div className="flex items-center justify-center">

        <img src={son} alt="" className="lg:w-[50%] rounded-2xl" />
        </div>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className='mt-5 flex flex-col gap-8'
        >
          <label className='flex flex-col'>
            <span className='text-black dark:text-white  mb-4'>Full Name</span>
            <input
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              placeholder="Full Name"
              className='bg-[#eee] dark:bg-[#555] py-4 px-6 text-black dark:text-white rounded-lg outline-none border-none'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-black dark:text-white  mb-4'>Email</span>
            <input
              type='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              placeholder="Email address"
              className='bg-[#eee] dark:bg-[#555] py-4 px-6 text-black dark:text-white rounded-lg outline-none border-none'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-black dark:text-white  mb-4'>Phone Number</span>
            <input
              type='tel'
              name='no'
              value={form.no}
              onChange={handleChange}
              placeholder="Phone Number"
              className='bg-[#eee] dark:bg-[#555] py-4 px-6 text-black dark:text-white rounded-lg outline-none border-none'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-black dark:text-white  mb-4'>Proposed Start Date</span>
            <input
              type='date'
              name='date'
              value={form.date}
              onChange={handleChange}
              placeholder="Proposed Start Date"
              className='bg-[#eee] dark:bg-[#555] py-4 px-6  text-black dark:text-white rounded-lg outline-none border-none'
            />
          </label>
          {/* <label className='flex flex-col'>
            <span className='text-black dark:text-white  mb-4'>Phone No</span>
            <input
              type='tel'
              name='no'
              value={form.no}
              onChange={handleChange}
              placeholder="What's your phone number?"
              className='bg-[#eee] dark:bg-[#555] py-4 px-6 placeholder:text-black dark:text-white text-black dark:text-white outline-none border-none'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-black dark:text-white  mb-4'>SSN</span>
            <input
              type='text'
              name='ssn'
              value={form.ssn}
              onChange={handleChange}
              placeholder="Your SSN?"
              className='bg-[#eee] dark:bg-[#555] py-4 px-6 placeholder:text-black dark:text-white text-black dark:text-white outline-none border-none'
            />
          </label> */}
          <label className='flex flex-col'>
            <span className='text-black dark:text-white  mb-4'>Project Description</span>
            <textarea
              rows={7}
              name='message'
              value={form.message}
              onChange={handleChange}
              placeholder='Project Description'
              className='bg-[#eee] dark:bg-[#555] py-4 px-6 text-black dark:text-white rounded-lg outline-none border-none'
            />
          </label>

          {/* <div>
          <label className="text-gray-0 m-5 text-center ">By Submiting this form, you agree to our <span onClick={() => setShows(!show)} className='text-[#555] cursor-pointer'>
            
            Terms And Conditions
            </span>
            <b className='text-gray-400'> *</b></label>
            {
              show &&
              <p className="text-gray-0 m-2 text-center ">
              THEORY
                JOB DESCRIPTION: PHOTOGRAPHER
                Department: Marketing
      Reports To: Marketing Manager
      Summary:
      We are seeking a Photographer who is passionate and creative to join our team and play
      a key role in developing and executing successful visual campaigns that drive brand
      awareness, lead generation, and sales. You will be responsible for a variety of tasks such
      as content creation, photo editing, and visual storytelling.
      Responsibilities:
      .You will need to download and sign up on our application; THEORY, to get
      acquainted with the technology.
      .Commit to creating compelling and high-quality visuals every day for users, advertisers, and creators.
      .You will need to create new accounts on Facebook, Instagram, and TikTok solely for the
      purpose of showcasing our visual content; you will be provided with the necessary guidance.
      .You will need to make a short, creative photography-based video within a period of one month; this video
      should be a marketing tool for the app.
       www.theory.com
      COMPANY STAMP
      JOB DESCRIPTION: PHOTOGRAPHER
      THEORY
      Company: THEORY
      This contract outlines the terms of payment between THEORY (hereinafter referred to as Company) and ……………………………… (hereinafter
      referred to as Employee) for the performance of photography services.
      1. Payment Structure.
      1.1 Payment Base.
      .Employee shall receive 40% commission at the end of a business week.
      .Commissions will be paid via wire transfer.
      2. Commissions.
      Employees are to be paid 40% of the monies paid via the Application on any device using
      Flutterwave as a payment method as this option forelisted will facilitate speedy payment
      of commission weekly.
      3. Term and Termination.
      This Agreement shall commence on Employment ………………………….. and shall continue until
      terminated by either party upon written notice.
      .This Agreement shall be terminated immediately by either party in the event of causes
      such as gross negligence, material breach of contract, or misconduct.
       www.theory.com
      COMPANY STAMP
      TERMS OF PAYMENT
      THEORY
      This Agreement constitutes the entire agreement between the parties with respect to the
      subject matter hereof and supersedes all prior or contemporaneous communications,
      representations, or agreements, whether oral or written. This Agreement may not be
      assigned by either party without the prior written consent of the other party. This
      agreement shall be governed and construed in accordance with the laws of The Federal
      Republic of Nigeria. If any provision of this Agreement is held invalid or unenforceable,
      such provision shall be struck and the remaining provisions shall be in full force and effect.
      IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first
      written above.
      THEORY Technology
      BY (authorized representative name)
      Title: (authorized representative title)
      (Photographer name)
      (Photographer signature)
      4. General Provisions.
      This Non-Disclosure Agreement (NDA) is made and entered into as of this (Date) by and
      between:
      THEORY; a social networking service for sharing photos, videos, audio, and text
      with its principal place of business (address) and
      (Photographer name) residing at (address)
      WHEREAS THEORY is engaged in the business of sharing photos, videos,
      text, and audio with monetary benefits services via social networking and possesses certain
      confidential information related to its business operations, including but not limited to
      trade secrets, inventions, marketing plans, technical know-how, financial information, and
      other proprietary information (collectively "Confidential Information"); and
      WHEREAS the Photographer will have access to certain Confidential Information of
      THEORY in the course of their engagement as a Photographer; and
      WHEREAS we decide to protect our Confidential Information from unauthorized
      disclosure, and the Photographer agrees to meet their obligations of confidentiality.
      NOW, THEREFORE, in consideration of the foregoing premises and the mutual covenant
      contained herein, both parties agree as follows:
       www.theory.com
      COMPANY STAMP
      NON-DISCLOSURE
      THEORY
      STAFF NAME:
      1. "Confidential Information" means all information disclosed by the company to the Photographer,
      whether orally, in writing, or by any other means, that is designated as confidential or
      that, by its nature or the circumstances surrounding its disclosure, ought to be treated as
      confidential. Confidential Information includes but is not limited to:
      . Trade secrets, know-how, and technical data;
      . Customer lists, marketing plans, and sales information;
      . Financial information including budgets, forecasts, and pricing;
      . Business plans and strategies;
      . Unpublished documents and software; and
      . Any other information designated as confidential by the Company.
      2. Confidentiality Obligations.
      The Photographer agrees to:
      . Use all reasonable efforts to protect the confidentiality of Confidential Information,
      including using the degree of care they use to protect their own confidential
      information;
      . Hold in confidence all confidential information of the Company and not disclose such to any
      third party without the prior written consent of the Company;
      . Not use Confidential Information for any purpose other than the performance of duties
      for the Company;
      . Not reverse engineer any Confidential Information; and
      . Upon termination of their engagement with the Company, return or destroy all Confidential
      Information in their possession or control.
       www.theory.com
      COMPANY STAMP
      NON-DISCLOSURE
      THEORY
      3. Exceptions.
      The obligation of confidentiality set forth in this Agreement shall not apply to Confidential
      Information that:
      . Is or becomes public through no fault of the Photographer;
      . Was rightfully known by the Photographer prior to its disclosure by the Company;
      . Is independently developed by the Photographer without the use of any Confidential
      Information; or
      . Is required to be disclosed by law or a court order.
      4. Remedies.
      The Photographer agrees that any unauthorized disclosure of Confidential Information would
      cause irreparable harm to the Company for which monetary damages would be an inadequate
      remedy. In the event of any unauthorized disclosure of Confidential Information, the Company
      shall be entitled to seek equitable relief, including injunctive relief, to prevent further
      disclosure and to protect its confidential information. Such remedies are in addition to any
      other legal or equitable remedies available to the Company.
      5. Term and Termination.
      This Agreement shall remain in effect for a period of two (2) months following the termination
      of the Photographer's engagement with the Company. This agreement may be terminated by either
      party upon written notice to the other party.
       www.theory.com
      COMPANY STAMP
      NON-DISCLOSURE
      THEORY
      6. General Provisions.
      This Agreement constitutes the entire agreement between the parties with respect to the
      subject matter hereof and supersedes all prior or contemporaneous communications,
      representations, or agreements, whether oral or written. This Agreement may not be
      assigned by either party without the prior written consent of the other party. This
      agreement shall be governed and construed in accordance with the laws of The Federal
      Republic of Nigeria. If any provision of this Agreement is held invalid or unenforceable,
      such provision shall be struck and the remaining provisions shall be in full force and effect.
      IN WITNESS WHEREOF, the parties have executed this Agreement as of the date first
      written above.
      THEORY Technology
      BY (authorized representative name)
      Title: (authorized representative title)
      (Photographer name)
      (Photographer signature)
       www.theory.com
      COMPANY STAMP
      NON-DISCLOSURE
      THEORY
      </p>
      
            }
        </div> */}

          <button
            type='submit'
            className='bg-[#eee] dark:bg-[#555] py-3 px-16 rounded-xl self-center outline-none w-fit text-black dark:text-white shadow-md shadow-primary'
          >
            {loading ? "Sending..." : "Submit"}
          </button>
        </form>
      </motion.div>
    </div>
  );
};

export default Form;