import React, { useEffect, useState } from "react";
import { Button, Upload, message, Tooltip } from "antd";
import { FaArrowCircleUp } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage, auth } from "../firebaseConfig";
import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { BiArrowToRight } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa6";
import Shop from "./Shop";
import { BsCart } from "react-icons/bs";

// import 'antd/dist/antd.css'; // Import Ant Design styles

function PublicProfile() {
    const {name} = useParams();

    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const currentUser = auth.currentUser;
    const currentURL = `${window.location.origin}/`;
    const [inputValue, setInputValue] = useState("");

  const [linksData, setLinksData] = useState([]);

  useEffect(() => {
    if (!name) return;

    const fetchUserByUsername = async () => {
      try {
        // Query Firestore for a user document by username
        const usersCollectionRef = collection(db, "userss");
        const userQuery = query(usersCollectionRef, where("username", "==", name));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userDocRef = doc(db, "userss", userDoc.id);
          const linksCollectionRef = collection(userDocRef, "Links");

          // Query to order the Links by a timestamp field in ascending order
          const orderedLinksQuery = query(linksCollectionRef, orderBy("createdAt", "asc"));

          // Listen for profile data
          const unsubscribeUser = onSnapshot(userDocRef, (documentSnapshot) => {
            setProfileData(documentSnapshot.data());
          });

          // Listen for links data
          const unsubscribeLinks = onSnapshot(orderedLinksQuery, (snapshot) => {
            const links = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setLinksData(links);
          });

          return () => {
            unsubscribeUser();
            unsubscribeLinks();
          };
        } else {
          console.error("User not found with username:", name);
        }
      } catch (error) {
        console.error("Error fetching user by username:", error);
      }
    };

    fetchUserByUsername();
  }, [name, db]);

  console.log(name, profileData, linksData)
  const navigate = useNavigate();


    const handleShare = () => {
        if (navigator.share) {
          navigator.share({
            title: `${name}'s Profile`,
            url: `${currentURL}${name}`,
          })
          .then(() => message.success("Profile shared successfully!"))
          .catch((error) => message.error("Error sharing profile: " + error));
        } else {
          message.warning("Share feature not supported on this browser.");
        }
      };

    const handleShop = () => {
          navigate("/#shop") 
      };

      const handleCopyLink = () => {
        const profileLink = `${currentURL}${name}`;
        navigator.clipboard.writeText(profileLink);
        message.success("Profile link copied to clipboard!");
      };

  return (
    <div
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   height: "100%",
      //   backgroundColor: "#f5efe4",
      //   textAlign: "center",
      // }}
      className="w-[100%] h-[100%] dark:bg-black items-center justify-center bg-[#f5efe4]"
    >

<div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        // backgroundColor: "#f5efe4",
        textAlign: "center",
      }}
      className="pt-24 lg:pt-28"
      >
        
        <div className="flex justify-center items-center mb-4">
          {profileData?.profileImage ? (
            <img
            src={profileData?.profileImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
              {name[0]?.toUpperCase()}
            </div>
          )}
        </div>

            <div className="flex items-center justify-center m-2">
      <h2 style={{ fontWeight: "bold" }} className="dark:text-white">{name}</h2>
      <Button
            icon={<ShareAltOutlined />}
            type="primary"
            onClick={handleShare}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-white ml-2"
            // className="bg-[#8B5A2B] border-none text-white ml-2"
          >
            Share
          </Button>
      </div>
      <a
            // icon={<BsCart />}
            // type="primary"
            // onClick={handleShop}
            href="#shop"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none mb-5 text-sm items-center justify-center self-center flex w-60 py-2 rounded-lg text-white"
          >
            <BsCart />
            <p className="ml-2">
            Shop to credit {name}
            </p>
          </a>
      </div>

       {/* <Tooltip title="Copy Profile Link">
          <Button
            icon={<CopyOutlined />}
            type="text"
            onClick={handleCopyLink}
            className="text-black font-semibold w-full text-center"
          >
            {currentURL + name}
          </Button>
        </Tooltip> */}
      {/* <p style={{ color: "gray", marginBottom: "32px" }}>FUTURIST</p> */}
       {/* Profile Link Copy Section */}
    

       {/* <div className="grid grid-cols-3 gap-3 mb-4">
  {["Facebook", "Instagram", "PayPal", "TikTok", "Zelle", "LinkedIn", "CashApp", "X", "WhatsApp", "Youtube", "Link", "Link"].map((link, index) => (
    <button
      key={index}
      className="bg-gray-100 shadow-lg p-3 lg:px-10 px-6 rounded text-sm w-full text-black"
    >
      {link}
    </button>
    ))}
</div> */}

<div className="grid gap-3 m-4 ">
  {
  // [
  //           { name: "Instagram", image: "https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Instagram-512.png" },
  //           { name: "PayPal", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/250_Paypal_logo-512.png" },
  //           { name: "Facebook", image: "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-facebook-512.png" },
  //           { name: "TikTok", image: "https://cdn0.iconfinder.com/data/icons/logos-brands-7/512/TikTok_logo_app0-512.png" },
  //           { name: "Zelle", image: "https://play-lh.googleusercontent.com/F4U2pL8z-Ic5FzCfe1xVXMWRvff6oEBIzDsyGRc4mE3bIUPiCfhuXXXvTOfcpVglKqs" },
  //           { name: "LinkedIn", image: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-512.png" },
  //           { name: "CashApp", image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Square_Cash_app_logo.svg/1200px-Square_Cash_app_logo.svg.png" },
  //           { name: "X", image: "https://freepnglogo.com/images/all_img/1725374683twitter-x-logo.png" },
  //           { name: "WhatsApp", image: "https://cdn2.iconfinder.com/data/icons/social-media-applications/64/social_media_applications_23-whatsapp-512.png" },
  //           { name: "Youtube", image: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/395_Youtube_logo-512.png" },
  //           { name: "Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
  //           { name: "Link", image: "https://cdn2.iconfinder.com/data/icons/interface-vol-2-1/16/link-chain-hyperlink-web-512.png" },
  //         ]
          linksData.map((link, index) => (
    // <button
    //   key={index}
    //   className="bg-gray-800 p-3 rounded text-sm w-full text-white"
    // >
    //   {link}
    // </button>
    <>
    {
      link.url &&
    <div className="text-start flex items-center justify-center ">
     <Link
      to={link.url}
      onClick={() => link?.url ? "" : alert(`${name} dosen't have this profile yet`)}
       className="text-start w-[100%] items-center p-5 rounded-2xl justify-center dark:bg-[rgba(52,52,52,0.3)] dark:shadow-gray-900 shadow-2xl">
    <div className="flex text-start items-center justify-between mx-2">
    <div className="flex text-start items-center justify-between">
      <img src={link.image} alt="Logo" className="w-10" />
<p className="ml-2 dark:text-white font-bold">

      {link.name}
</p>
      </div>
<FaArrowRight className="dark:text-white" />
      </div>

    <div className=" text-start">

      {/* <Link
      to={link.url}
      onClick={() => link?.url ? "" : alert(`${name} dosen't have this profile yet`)}
      key={index}
      className="bg-gray-100 shadow-lg p-3 lg:px-10 px-6 dark:text-white dark:bg-[#111] rounded-2xl text-sm w-full text-black"
    >
      {link.name}
    </Link> */}

  </div>
  </Link>
  </div>
    }
    </>
    ))}
</div>



      <Button
        type="default"
        href="/register" // Replace with your actual link
        style={{
          backgroundColor: "#fff",
          color: "#000",
          padding: "10px 20px",
          borderRadius: "20px",
          fontWeight: "bold",
          textTransform: "none",
          margin: 30
        }}
      >
        Join {name} on Vibe Fine Arts
      </Button>

      <span id="shop"></span>
      <Shop title={"me"} name={name}/>
      {/* <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
        <FaArrowCircleUp size={40} color="#ffba00" />
      </div> */}
    </div>
  );
}

export default PublicProfile;
