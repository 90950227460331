import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch('/create-intent', {
      method: 'POST',
    });

    const {client_secret: clientSecret} = await res.json();

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
         {/* Delivery Address Fields */}
  {/* <div className="delivery-address">
    <label>
      Street Address:
      <input 
        type="text" 
        name="street" 
        required 
        className="input-field"
        placeholder="123 Main St" 
      />
    </label>
    <label>
      City:
      <input 
        type="text" 
        name="city" 
        required 
        className="input-field"
        placeholder="City" 
      />
    </label>
    <label>
      State:
      <input 
        type="text" 
        name="state" 
        required 
        className="input-field"
        placeholder="State" 
      />
    </label>
    <label>
      Postal Code:
      <input 
        type="text" 
        name="postalCode" 
        required 
        className="input-field"
        placeholder="Postal Code" 
      />
    </label>
  </div> */}

      <PaymentElement />
      {/* <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button> */}
      <div className='items-center justify-center flex mt-5'>
        <p className='text-sm'>Secured by</p>
      <img className='w-20 ml-2' src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png" alt="Stripe" />
      </div>
      <button
      type="submit" disabled={!stripe || !elements}
            // onClick={() => addItem(productData)}
            className="my-4 flex text-sm cursor-pointer w-[95%] ml-2.5 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-black text-white duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            {/* <BsCartPlus size="1.8rem" className="" /> */}
            Pay Now
          </button>
      {/* <button
      type="submit" 
    //   disabled={!stripe || !elements}
            // onClick={() => addItem(productData)}
            className="my-4 flex text-sm cursor-pointer w-[95%] ml-2.5 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-black text-white duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            Discount With Credit
          </button> */}
      <button
      type="submit" 
    //   disabled={!stripe || !elements}
            // onClick={() => addItem(productData)}
            className="my-4 flex text-sm cursor-pointer w-[95%] ml-2.5 self-center text-center justify-center items-center hover:text-gray-50 hover:bg-gray-400 drop-shadow-2xl shadow-2xl py-4 px-6 bg-black text-white duration-500 rounded-full "
            // className="bg-dark-primary mt-2 mb-2 p-1 rounded-2xl bg-white  hover:bg-green-600 hover:text-white duration-500  text-green-600"
            style={{ borderRadius: "20", border: 0 }}
          >
            Pay on delivery
          </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm