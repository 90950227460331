import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// const firebaseConfig = {
//   // apiKey: "AIzaSyCT4VYyzHbWO0zgrjOgIixAvfqeX1wWZYY",
//   // authDomain: "limoh-e27e5.firebaseapp.com",
//   // projectId: "limoh-e27e5",
//   // storageBucket: "limoh-e27e5.appspot.com",
//   // messagingSenderId: "289133088100",
//   // appId: "1:289133088100:web:f04c4e18b16717bd493e71",
//   // measurementId: "G-EMR5VNJSF2"
//   apiKey: "AIzaSyAh8mqzU_Syzwjxc9a-pQjSIWYBWg8GTmU",
//   authDomain: "new-york--culture-club.firebaseapp.com",
//   projectId: "new-york--culture-club",
//   storageBucket: "new-york--culture-club.firebasestorage.app",
//   messagingSenderId: "169423627901",
//   appId: "1:169423627901:web:b41abce2bcf488b22ab314",
//   measurementId: "G-T2175L5ZD7"
// };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvfSZiORsVohwIB696oyJYwxBcuh-jRRY",
  authDomain: "markers-digital-pro.firebaseapp.com",
  projectId: "markers-digital-pro",
  storageBucket: "markers-digital-pro.appspot.com",
  messagingSenderId: "99311584893",
  appId: "1:99311584893:web:63167d001bb5c569f31314",
  measurementId: "G-H43QCYF1XV"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const auth = firebase.auth();
const firestore = firebase.firestore();
const db = firebase.firestore();
const storage = firebase.storage();
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error) {
    console.error("Error during Google Sign-In:", error);
  }
};


export { auth, firestore, firebase, db, storage, firebaseConfig, signInWithGoogle }; // Export the modules

export default firebase;


// NXB
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
//   authDomain: "nextberries-cb5b9.firebaseapp.com",
//   projectId: "nextberries-cb5b9",
//   storageBucket: "nextberries-cb5b9.appspot.com",
//   messagingSenderId: "44749615279",
//   appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
//   measurementId: "G-9QZ27VRVHL",
// };

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
// firebase.auth().onAuthStateChanged((user) => {
//   if (user) {
//     // User is signed in
//     console.log("User is signed in:", user);
//   } else {
//     // User is signed out
//     console.log("User is signed out");
//   }
// });

// export default firebase;
