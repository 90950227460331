import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db, storage, auth } from "../firebaseConfig";
// import logo from "./link.png";
// import logo from "./Asset/NEW YORK LOGO-04.jpg";
import logo from "./Asset/log.png";
import log from "./Asset/nfc.png";
import { Button, Upload, message, Tooltip } from "antd";
import firebase from "../firebaseConfig";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";


const UsernameInput = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { uid, email, usernames } = location.state || {};  // Destructure email as well
  const [error, setError] = useState(null);
  const currentUser = auth?.currentUser;
  const currentURL = `${window.location.origin}/`;
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  
  const [profileData, setProfileData] = useState(null);
   // fetch the user data from firebase
   useEffect(() => {
    const subscriber = db
        .collection('userss')
        .doc(currentUser?.uid)
        .onSnapshot(documentSnapshot => {
          setProfileData(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
  }, [currentUser, profileData]);

  const [username, setUsername] = useState("");

  
  // useEffect(() => {
  //   const checkIfUserExists = async () => {
  //     if (currentUser?.uid) {
  //       try {
  //         // Check if user document exists
  //         const userDoc = await getDoc(doc(db, "userss", currentUser?.uid));
  //         if (userDoc.exists()) {
  //           const userData = userDoc.data();
  //           // setUsername(userData.username)
  //           // If the user already has an account or username, redirect to profile
  //           if (userData.email === email || userData.username) {
  //             navigate("/profile");
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error checking user existence:", error);
  //       }
  //     }
  //   };
  //   checkIfUserExists();
  // }, [currentUser?.uid, email, navigate]);

  // console.log(currentUser?.uid, usernames, email, profileData)
  const handleSaveUsername = async () => {
    const finalUsername = username || profileData?.username;
  
    // Validate that we have a username and user ID
    if (!currentUser?.uid || !finalUsername) {
      setError("Please provide a valid username.");
      return;
    }
  
    try {
      // Check if the username is already taken
      const usedUsernameDoc = await getDoc(doc(db, "usedUsernames", finalUsername));
      if (usedUsernameDoc.exists() && usedUsernameDoc.data()?.uid !== currentUser.uid) {
        setError("Username is already taken. Please choose a different one.");
        return;
      }
  
      // Save the username in the user's document
      await updateDoc(doc(db, "userss", currentUser.uid), {
        username: finalUsername
      });
  
      // Add the username to the usedUsernames collection to reserve it
      await setDoc(doc(db, "usedUsernames", finalUsername), {
        uid: currentUser.uid,
        timestamp: new Date()
      });
  
      // Call the image upload handler if defined
      if (typeof handleImageUpload === "function") {
        await handleImageUpload();
      }
  
      // Navigate to the profile screen
      navigate("/profile");
    } catch (error) {
      console.error("Error updating username:", error);
      setError("An error occurred while saving your username.");
    }
  };
    

  
    const handleImageUpload = async () => {
      if (profileImage) {
        setLoading(true);
        try {
          const imageRef = ref(storage, `profileImages/${currentUser.uid}`);
          await uploadBytes(imageRef, profileImage);
          const imageUrl = await getDownloadURL(imageRef);
  
          await updateDoc(doc(db, "userss", currentUser.uid), {
            profileImage: imageUrl,
          });
  
          setProfileData((prevData) => ({
            ...prevData,
            profileImage: imageUrl,
          }));
  
          message.success("Profile image updated successfully!");
        } catch (error) {
          message.error("Error uploading image: " + error.message);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("Please select an image to upload.");
        // message.warning("Please select an image to upload.");
      }
    };
    const uploadProps = {
      beforeUpload: (file) => {
        setProfileImage(file);
        return false;
      },
      onRemove: () => {
        setProfileImage(null);
      },
    };

  return (
    <div className="min-h-screen flex flex-col justify-center p-3 items-center dark:bg-black dark:text-white bg-white">
    {/* <img src={logo} className="w-40 -mt-16 text-start" alt="" /> */}

      {/* Image visible in dark mode */}
  {/* <img src={log} className="hidden dark:inline w-60 -mt-20 lg:-mt-0" alt="Dark Mode Logo" /> */}

{/* Image visible in light mode */}
{/* <img src={logo} className="inline dark:hidden w-60 -mt-20 lg:-mt-0" alt="Light Mode Logo" /> */}

      <h2 className="text-2xl font-bold mb-2 mt-20">Welcome to the Vibe Fine Arts!</h2>
      <p className="mx-5">Choose your Digital Twin username. You can always change it later.</p>
      <div className="flex justify-center items-center m-4">
          {profileData?.profileImage ? (
            <img
              src={profileData?.profileImage}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-400 flex items-center justify-center text-2xl">
              {"O"}
            </div>
          )}
        </div>
              {/* Upload Section */}
              <div className="text-center">
          <Upload {...uploadProps} listType="picture" maxCount={1}>
            <Button type="dashed" className="text-[#555]">
              Select Profile Image
            </Button>
          </Upload>

          {
            profileImage &&
          <Button
            type="primary"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-white mt-4"
            onClick={handleImageUpload}
            loading={loading}
            disabled={!profileImage}
          >
            Upload Image
          </Button>
          }
        </div>

      <input
        type="text"
        value={`${currentURL}${username}`}
        onChange={(e) => setUsername(e.target.value.replace(currentURL, ""))}
        placeholder="Enter your username"
        className="px-4 py-2 mb-4 mt-5 dark:text-white dark:bg-[#111] h-12 border w-80 outline-blue-400 rounded-lg"
      />
      {error && <p className="text-red-500">{error}</p>}
      <button onClick={handleSaveUsername} className="px-6 py-3 bg-gradient-to-r from-violet-600 to-indigo-600 w-60 mb-3 text-white rounded-full">
        Continue
      </button>
      <button onClick={() => navigate('/profile')} className="px-6 py-2  w-60 border-2 rounded-full">
        Skip
      </button>
    </div>
  );
};

export default UsernameInput;
