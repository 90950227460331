import React, { useEffect, useState } from "react";
import { doc, getDoc, orderBy, query, updateDoc } from "firebase/firestore";
import { collection, onSnapshot } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage, auth } from "../firebaseConfig";
import { Button, Upload, message, Tooltip } from "antd";
import { CopyOutlined, ShareAltOutlined } from "@ant-design/icons";
import { FaPlusCircle } from "react-icons/fa";
import { BiChip } from "react-icons/bi";
import { Link } from "react-router-dom";
import firebase from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import Shop from "./Shop";



const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const currentUser = auth.currentUser;
  const currentURL = `${window.location.origin}/`;
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();


  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  const [linksData, setLinksData] = useState([]);

  useEffect(() => {
    if (!currentUser?.uid) return;
  
    const userDocRef = doc(db, "userss", currentUser.uid);
    const linksCollectionRef = collection(userDocRef, "Links");
  
    // Query to order the Links by a timestamp field in descending order
    const orderedLinksQuery = query(linksCollectionRef, orderBy("createdAt", "asc"));
  
    const unsubscribeUser = onSnapshot(userDocRef, (documentSnapshot) => {
      setProfileData(documentSnapshot.data());
    });
  
    const unsubscribeLinks = onSnapshot(orderedLinksQuery, (snapshot) => {
      const links = snapshot.docs.map((doc) => ({
        id: doc.id, // Include the document ID if needed
        ...doc.data(),
      }));
      setLinksData(links); // Assuming setLinksData is a state updater for Links
    });
  
    return () => {
      unsubscribeUser();
      unsubscribeLinks();
    };
  }, [currentUser]);


  useEffect(() => {
    
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Remove user data from local storage
        localStorage.removeItem("userData");

        setUser(null);
        navigate('/');
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

      })
      .catch((error) => {
        console.error(error);
      });
  };

  // useEffect(() => {
  //   const fetchUserProfile = async () => {
  //     if (currentUser) {
  //       const userDoc = await getDoc(doc(db, "userss", currentUser.uid));
  //       if (userDoc.exists()) {
  //         setProfileData(userDoc.data());
  //       } else {
  //         message.error("User profile not found!");
  //       }
  //     }
  //   };
  //   fetchUserProfile();
  // }, [currentUser]);

   // fetch the user data from firebase
   useEffect(() => {
    const subscriber = db
        .collection('userss')
        .doc(currentUser?.uid)
        .onSnapshot(documentSnapshot => {
          setProfileData(documentSnapshot.data());
        });
    
    // Stop listening for updates when no longer required
    return () => subscriber();
  }, [currentUser]);

  const handleImageUpload = async () => {
    if (profileImage) {
      setLoading(true);
      try {
        const imageRef = ref(storage, `profileImages/${currentUser.uid}`);
        await uploadBytes(imageRef, profileImage);
        const imageUrl = await getDownloadURL(imageRef);

        await updateDoc(doc(db, "userss", currentUser.uid), {
          profileImage: imageUrl,
        });

        setProfileData((prevData) => ({
          ...prevData,
          profileImage: imageUrl,
        }));

        message.success("Profile image updated successfully!");
      } catch (error) {
        message.error("Error uploading image: " + error.message);
      } finally {
        setLoading(false);
      }
    } else {
      message.warning("Please select an image to upload.");
    }
  };

  const handleCopyLink = () => {
    const profileLink = `${currentURL}${profileData.username}`;
    navigator.clipboard.writeText(profileLink);
    message.success("Profile link copied to clipboard!");
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `${profileData.username}'s Profile`,
        url: `${currentURL}${profileData.username}`,
      })
      .then(() => message.success("Profile shared successfully!"))
      .catch((error) => message.error("Error sharing profile: " + error));
    } else {
      message.warning("Share feature not supported on this browser.");
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      setProfileImage(file);
      return false;
    },
    onRemove: () => {
      setProfileImage(null);
    },
  };

  const uid = profileData?.uid
  const usernames = profileData?.username
  const email = profileData?.email
  console.log(uid, usernames, email)

  const [urls, setUrls] = useState(
    linksData.reduce((acc, link) => {
      acc[link.id] = link.url || ""; // Initialize state with existing URLs or empty strings
      return acc;
    }, {})
  );

  const handleInputChange = (id, value) => {
    setUrls((prevUrls) => ({
      ...prevUrls,
      [id]: value,
    }));
  };

  const handleSaveUrl = async (id) => {
    try {
      const linkDocRef = doc(db, "userss", currentUser.uid, "Links", id);
      await updateDoc(linkDocRef, { url: urls[id] });
      alert("URL updated successfully!");
    } catch (error) {
      console.error("Error updating URL:", error);
      alert("Failed to update URL.");
    }
  };



  if (!profileData) {
    return <div className="dark:text-white w-[100%] h-[100%]">Loading profile...
      {/* <button
            onClick={handleSignOut}
            className=" bg-gradient-to-r from-violet-600 to-indigo-600 z-50 m-20 p-1 rounded-lg"
          >
                      <p className="px-12 py-1 flex-row text-white flex items-center" >
            Sign Out
            </p>
          </button> */}
    </div>;
  }

  

  return (
    <div className="lg:pt-24 pt-20 dark:bg-[#000] dark:text-white p-0 flex flex-col items-center">
      <div className="dark:bg-[#000] rounded-lg shadow-lg w-full py-5">
        <div className="flex justify-center items-center mb-1">
          {/* <p className="font-semibold m-2 text-sm dark:text-[#ccc]">{`Token balance ${profileData.balance}`}  </p> */}
          {profileData?.profileImage ? (
            <img
            src={profileData?.profileImage}
            alt="Profile"
            className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
              {profileData?.username[0]?.toUpperCase()}
            </div>
          )}
        </div>
          <p className=" font-bold">{profileData.username}  
            </p>

        <div className="text-center mb-4">
            <Link
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            to={"/username-input"}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none px-4 ml-2 text-sm p-1 rounded-lg text-white"
          >
            Edit
          </Link>
            <Link
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            to={"/rewards"}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none px-4 m-2 text-sm p-1 rounded-lg text-white"
          >
            Social Commerce
          </Link>
            <Link
            // type="primary"
            // onClick={() => navigate("/username-input", { state: { uid, email, usernames } })}
            to={`${currentURL}${profileData.username}`}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none px-4 text-sm p-1 rounded-lg text-white"
          >
            Preview
          </Link>
          <p className="text-sm mt-2 text-[#555]">{profileData?.email || "User email"}</p>
        </div>

          {/* Upload Section */}
          {/* <div className="text-center">
          <Upload {...uploadProps} listType="picture" maxCount={1}>
            <Button type="dashed" className="text-[#555]">
              Select Profile Image
            </Button>
          </Upload>
          <Button
            type="primary"
            className="bg-gray-700 border-none dark:text-[#333] mt-4"
            onClick={handleImageUpload}
            loading={loading}
            disabled={!profileImage}
          >
            Upload Image
          </Button>
        </div> */}

        
        {/* Profile Link Copy Section */}
        <Tooltip title="Copy Profile Link">
          <Button
            icon={<CopyOutlined />}
            type="text"
            onClick={handleCopyLink}
            className="dark:text-white font-semibold w-full text-xs text-center"
          >
            {currentURL + profileData.username}
          </Button>
        </Tooltip>

        <div className="mb-5 flex justify-center">
          {/* <Button
            icon={<ShareAltOutlined />}
            type="primary"
            onClick={handleShare}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none text-sm text-white"
          >
            Share Link
          </Button> */}
          {/* <Button
            icon={<BiChip />}
            type="primary"
            onClick={() => navigate('https://www.wakdev.com/en/apps.html')}
            // onClick={() => alert("Delivery address required")}
            className="bg-gradient-to-r from-violet-600 to-indigo-600 border-none ml-2 text-white"
          >
           Write My Chip
          </Button> */}
          <a
            // icon={<BsCart />}
            // type="primary"
            // onClick={handleShop}
            onClick={handleShare}
            href=""
            // href="https://apps.apple.com/us/app/nfc-tools/id1252962749"
            // href="https://www.wakdev.com/en/apps.html"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 text-sm  border-none items-center text-center justify-center self-center flex p-1 px-3 ml-2 rounded-lg text-white"
          >
<ShareAltOutlined />
            <p className="ml-2">
            Share Link
            </p>
          </a>
          <a
            // icon={<BsCart />}
            // type="primary"
            // onClick={handleShop}
            href="/register"
            // href="/setup"
            // href="https://apps.apple.com/us/app/nfc-tools/id1252962749"
            // href="https://www.wakdev.com/en/apps.html"
            className="bg-gradient-to-r from-violet-600 to-indigo-600 text-sm  border-none items-center text-center justify-center self-center flex p-1 px-3 ml-2 rounded-lg text-white"
          >
            <BiChip />
            <p className="ml-2">
            Write my Chip
            </p>
          </a>
        </div>

      

        {/* Social Links Section */}
        <div className="rounded-md p-4 mb-6">
          {/* <h3 className="text-lg font-semibold mb-3 text-center">Add Links</h3> */}
          {/* <div className="grid grid-cols-3 gap-3 mb-4">
            {Array(9).fill("Facebook, Instagram, PayPal, TikTok, Zelle, LinkedIn, CashApp, X, WhatsApp").map((link, index) => (
              <button
                key={index}
                className="bg-gray-800 p-3 rounded text-sm w-full text-white"
              >
                {link}
              </button>
            ))}
          </div> */}
       <div className="grid gap-3 mb-4">
      {linksData.map((link) => (
        <div
          key={link.id}
          className="text-start flex items-center justify-center"
        >
          <div className="text-start w-[100%] items-center p-5 rounded-2xl justify-center dark:bg-[rgba(52,52,52,0.3)] shadow-2xl">
            <div className="flex text-start items-center mb-2 mx-1">
              <img src={link.image} alt="Logo" className="w-10" />
              <p className="ml-2">{link.name}</p>
            </div>

            <div className="text-start grid">
              <input
                type="text"
                value={urls[link.id]}
                onChange={(e) => handleInputChange(link.id, e.target.value)}
                className="dark:bg-[#222] bg-[#eee] p-3 rounded-2xl text-sm w-[100%] dark:text-white"
                placeholder={link.url ? link.url :`Your ${link.name} URL with https://`}
              />
              <button
                onClick={() => handleSaveUrl(link.id)}
                className="mt-2 bg-blue-600 text-white p-2 rounded-2xl"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>

          {/* <div className="flex justify-end">
            <Button
              type="primary"
              icon={<FaPlusCircle />}
              className="bg-white text-black rounded-full font-semibold"
            >
              Add new social Link
            </Button>
          </div> */}
        </div>

      </div>

      <button
            onClick={handleSignOut}
            className=" bg-gradient-to-r from-violet-600 to-indigo-600 z-50 m-20 p-1 rounded-lg"
          >
                      <p className="px-12 py-1 flex-row text-white flex items-center" >
            Sign Out
            </p>
          </button>
      <Shop title={"me"} />
    </div>
  );
};

export default Profile;
