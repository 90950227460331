import React, { useEffect, useState } from 'react'
import { BiShoppingBag, BiSolidMoon, BiSolidSun } from "react-icons/bi";
import logo from "./Asset/vfa.png"
import Logo from "./Asset/vfa.png"
import { auth, db, signInWithGoogle } from "../firebaseConfig";
import firebase from "../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { HiMail, HiMenu, HiMenuAlt1, HiMenuAlt3 } from 'react-icons/hi';
import ResponsiveMenu from './ResponsiveMenu';
import { MdCall } from 'react-icons/md';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Navlinks } from './Data';
import { useCart } from "react-use-cart";
import { CgClose } from 'react-icons/cg';



const Nav = () => {
  const navigate = useNavigate();
  const currentUser = auth.currentUser;


  const handleGoogleSignIn = async () => {
    const user = await signInWithGoogle();
    if (user) {
      // Handle signed-in user
      navigate("/profile");
      console.log("Signed in as:", user.displayName); 
    }
  };

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const [theme, setTheme] = useState(
    // localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );
  const element = document.documentElement;

  // console.log(theme)

  useEffect(() => {
    if (theme === "dark") {
      // element.classList.add("dark");
      // localStorage.setItem("theme", "dark");
      element.classList.add("dark");
      // localStorage.setItem("theme", "light");
    } else {
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
      // localStorage.setItem("theme", "dark");
      // element.classList.remove("dark");
    }
  }, [theme]);

  const [profileData, setProfileData] = useState(null);
 // fetch the user data from firebase
 useEffect(() => {
  const subscriber = db
      .collection('userss')
      .doc(currentUser?.uid)
      .onSnapshot(documentSnapshot => {
        setProfileData(documentSnapshot.data());
      });
  
  // Stop listening for updates when no longer required
  return () => subscriber();
}, [currentUser]);

  
  const [user, setUser] = useState(null);

  const [CSuser, setCSUser] = useState();
  //fetch the user data from firebase
  // useEffect(() => {
  //   const subscriber = firebase.firestore()
  //       .collection('Users')
  //       .doc(firebase.auth().currentUser.uid)
  //       .onSnapshot(documentSnapshot => {
  //           setCSUser(documentSnapshot.data());
  //       });
    
  //   // Stop listening for updates when no longer required
  //   return () => subscriber();
  //   }, []);

  //   console.log(CSuser)

  useEffect(() => {
    // Check if user data exists in local storage
    const userData = localStorage.getItem("userData");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup the event listener on unmount
  }, []);

  const { isEmpty, totalItems } = useCart();


  return (
    <nav className="lg:w-[90%] w-[95%] lg:h-20 z-10 fixed justify-self-center text-center my-3 flex justify-between items-center lg:p-5 p-3 lg:px-10 bg-[rgba(52,52,52,0.3)] shadow-md rounded-full">
    {/* <nav className="lg:w-[90%] w-[95%] h-20 z-10 fixed justify-self-center text-center my-3 flex justify-between items-center lg:p-5 p-3 lg:px-10 bg-[rgba(255,255,255,0.3)] dark:bg-[rgba(52,52,52,0.3)] shadow-md rounded-full"> */}
    <Link 
    to='/'  
    onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }}  
               className="text-2xl font-bold flex items-center cursor-pointer text-green-900">
      {/* <span className="mr-2"> </span> <spn className="hidden lg:inline">Linktree</spn> */}
      <img className="w-10 ml-2 lg:hidden rounded-full" src={logo} alt="" />
      <img className="w-16 hidden rounded-full lg:inline" src={Logo} alt="" />
    </Link>
    {
      user &&
    <p className="font-semibold mx-2 text-sm text-white">{`Credit ${profileData?.balance?.toLocaleString() || 0}`}  </p>
    }
    <ul className="hidden md:flex space-x-10 text-white ">
            {Navlinks.map((data) => (
              <li
              onClick={()=>setShowMenu(false)}
              >
                <a href={data.link} className="text-sm font-semibold inline-block">
                {data?.name}
                </a>
              </li>
            ))}
      <a href="mailto:info@Vibe Fine Arts.com" >
        <HiMail className="text-2xl cursor-pointer" />
      </a>
                  <a href="tel:+1 (702) 764-7232" >
                    <MdCall className="text-2xl cursor-pointer" />
                   </a>
    </ul>
    <div className="space-x-2 align-middle flex justify-evenly items-center">
      {/* <button onClick={handleGoogleSignIn} className="px-5 py-3 rounded-full font-bold bg-gray-200 text-green-900">
        Log in
      </button> */}
    
      {/* <button onClick={handleGoogleSignIn} className="lg:px-8 py-3 px-5 rounded-full font-semibold hover:shadow-lg
       bg-gray-800 text-gray-300 shadow-md">
        Login
      </button> */}
         <Link to="/cart"
         onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }} 
         className="mr-2">
            <BiShoppingBag
              // size="30"
              className="h-6 w-6"
              color={ `#fff`}
              // className="rounded-full shadow-2xl drop-shadow-2xl shadow-gray-700 "
            />
            {!isEmpty && (
              <div className=" absolute top-5 ml-3 text-sm text-white rounded-full w-6 h-6 text-center bg-orange-600">
               <p className='mt-0.5'>
                {totalItems}
                </p> 
              </div>
            )}
          </Link>
        <div
        //  className='hidden lg:inline'
        className='px-2'
         >
          {theme === "dark" ? (
            <BiSolidSun onClick={() => setTheme("light")} className="text-2xl text-white cursor-pointer" />
          ) : (
            <BiSolidMoon  onClick={() => setTheme("dark")} className="text-2xl text-white cursor-pointer" />
          )}
      </div>

      {
        user ?
        <>
        {
          profileData?.profileImage ?
          <Link to='/profile' 
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }}  
          className="w-10 h-10 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
          <img src={profileData?.profileImage} alt="Profile Picture" className="h-10 w-10 rounded-full" />
          </Link>
          :
        <Link to='/profile' className="w-10 h-10 rounded-full bg-gray-400 text-white flex items-center justify-center text-2xl">
        {!user?.displayName ? "O" : user?.displayName[0]?.toUpperCase()}
      </Link>
        }
        </>
        :
        <Link to={"/register"} 
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }}  
        // onClick={handleGoogleSignIn}
        className="lg:px-8 py-2 px-5 rounded-full hover:shadow-lg
         dark:bg-[#555] bg-[#111] text-white text-sm shadow-md">
        Login
        </Link> 
      }

    <div className="pr-2 lg:hidden"> 
    {showMenu ? (
              <CgClose
                onClick={toggleMenu}
                className="font-bold cursor-pointer rounded-full p-1 transition-all"
                size={40}
                color='white'
              />
            ) : (
              <HiMenu
                onClick={toggleMenu}
                className="cursor-pointer transition-all"
                size={25}
                color='white'

              />
            )}


    </div>

    
    </div>
    <ResponsiveMenu showMenu={showMenu} theme={theme} setTheme={setTheme} setShowMenu={setShowMenu} />
  </nav> 
  )
}

export default Nav