import { useEffect, useState } from "react";
import { collection, query, orderBy, limit, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const usersCollectionRef = collection(db, "userss");

    // Query to get top 3 users based on balance in descending order
    const topUsersQuery = query(usersCollectionRef, orderBy("balance", "desc"), limit(3));

    const unsubscribe = onSnapshot(topUsersQuery, (snapshot) => {
      const topUsers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeaderboardData(topUsers);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
              <span id="Leaderboard"></span>
      <div className=" lg:w-[80%] w-[100%] pt-10">
        <h1 className="text-center text-3xl font-bold dark:text-white text-gray-800">Leaderboard</h1>
        <h1 className="text-center dark:text-white text-gray-800">Meet our top credit leader's for the week</h1>
        <div className="p-6 space-y-6">
          {leaderboardData.map((user, index) => (
            <div
              key={user.id}
              className={`flex items-center justify-between rounded-xl p-4 ${
                index === 0
                  ? 'bg-yellow-300 text-gray-800'
                  : index === 1
                  ? 'bg-gray-300 text-gray-800'
                  : 'bg-yellow-700 text-white'
              } shadow-lg`}
            >
              <div className="flex items-center space-x-4">
                <img
                  src={user?.profileImage || "https://via.placeholder.com/50"}
                  alt={`${user?.username || "User"}'s Avatar`}
                  className="w-12 h-12 rounded-full border-2 border-white"
                />
                <div>
                  <h3 className="text-lg font-semibold">{user?.username || "Anonymous"}</h3>
                  <p className="text-sm">Credit: $xxxxx</p>
                </div>
              </div>
              <div>
                <span className="text-2xl font-bold">#{index + 1}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
