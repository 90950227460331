import React, { useState } from 'react';

const RewardsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTerms = () => {
    setIsOpen(!isOpen);
  };

  return (
 <div className="bg-gradient-to-r from-blue-500 to-green-400 p-10 pt-24 min-h-screen flex flex-col items-center justify-center text-white">
    {/* <div className="dark:bg-gradient-to-r from-black to-yellow-900 p-10 min-h-screen flex flex-col items-center justify-center dark:text-white"> */}
      <div className="text-center mb-8">
        <h1 className="text-4xl font-semibold mb-4">Exclusive Discount Offer</h1>
        <p className="text-lg lg:px-10">Enjoy up to 20% off on individual pieces from our talented gallery-represented artists. It's the perfect opportunity to enhance your collection with exceptional works.</p>
      </div>

      <div className="bg-white text-black rounded-lg p-8 shadow-lg w-full max-w-lg">
        <div className="mb-6">
          <h2 className="text-lg font-bold mb-2">The Rewards Marketplace</h2>
          <p className="text-sm mb-4">Claim your discount with the following credit redemption values:</p>
          <ul className="list-disc text-sm pl-6 space-y-2">
            <li>500 credits = 5% off</li>
            <li>1000 credits = 10% off</li>
            <li>2000 credits = 20% off</li>
          </ul>
        </div>

        <div>
          <button 
            className="text-blue-600 font-semibold" 
            onClick={toggleTerms}
          >
            Terms and Conditions
          </button>

          {isOpen && (
            <div className="mt-4 p-4 bg-gray-100 rounded-lg shadow-lg">
              <p className="mb-2 font-semibold">Enjoy up to 20% off on individual pieces from our talented gallery-represented artists. It's the perfect opportunity to enhance your collection with exceptional works.</p>
              <ul className="list-disc pl-6 mb-4">
                <li>Maximum discount of 20% per piece.</li>
                <li>Discounts apply only to gallery-represented artists.</li>
                <li>No bundling of discounts.</li>
                <li>Blue-chip artists are excluded from this offer.</li>
              </ul>
              <p>Stop by the gallery or contact us to take advantage of this promotion!</p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-8">
        <a href='/shop'
        onClick={()=> alert("Please redeem your credits in the marketplace")}
        className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-6 rounded-full">
          Redeem Now
        </a>
      </div>
    </div>
  );
};

export default RewardsPage;
