import React, { useEffect, useState } from "react";
// import { useThemeHook } from "../GlobalComponents/ThemeProvider";
import { BiSearch } from "react-icons/bi";
import SearchFilter from "react-filter-search";
import ProductCard from "./ProductCard";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import firebaseConfig from "../firebaseConfig";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();
const Homes = ({title}) => {
  // const [theme] = useThemeHook();
  const [searchInput, setSearchInput] = useState("");
  const [productData, setProductData] = useState([]);
  // const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function getResponse() {
      // Initialize Firebase
      // const firebaseConfig = {
      //   // Your Firebase configuration
      //   apiKey: "AIzaSyCRSVN6rIpkPQKLz77NFcES36Fpe665T9k",
      //   authDomain: "nextberries-cb5b9.firebaseapp.com",
      //   projectId: "nextberries-cb5b9",
      //   storageBucket: "nextberries-cb5b9.appspot.com",
      //   messagingSenderId: "44749615279",
      //   appId: "1:44749615279:web:0b3e110f248c8e8b3ae45a",
      //   measurementId: "G-9QZ27VRVHL",
      // };
      const firebaseConfig = {
        apiKey: "AIzaSyB6Y6C1T0Wm3RFekEvTeqP1SbdQkDiSYwc",
        authDomain: "agency-b5b65.firebaseapp.com",
        projectId: "agency-b5b65",
        storageBucket: "agency-b5b65.appspot.com",
        messagingSenderId: "30425467879",
        appId: "1:30425467879:web:aef3dadb4967b61c258b42",
        measurementId: "G-1QKVN1SZDF"
      };
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      const db = firebase.firestore();
      const collection = db.collection("Products").orderBy("createdAt", "asc")
      const snapshot = await collection.get();
      const Products = [];

      snapshot.forEach((doc) => {
        Products.push({
          id: doc.id,
          ...doc.data(),
        })
      });

      setProductData(Products);
    }

    getResponse();
  }, []);
  return (
    <div className="h-[100%] w-[100%]"       
    >
      <div className={`text-center ${title === 'me' ? "pt-0" : "pt-20"} `}>
        <h1 className=" pt-10 text-2xl dark:text-white">
       Shop Collection
        </h1>
        <div className="relative">
          <BiSearch
            size="16"
            className="absolute md:left-[41%] left-[23%] z-20 top-[32px]"
            color="gray"
          />
          <input
            placeholder="Search more"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="text-[#555] bg-[#eee] h-10 lg:w-96 w-[300px] rounded-md m-5 text-center outline-none text-sm"
          />
        </div>
      </div>
         {/* <h1 className="text-gray-900 text-4xl lg:text-6xl text-center m-5">
        Collection
      </h1>
         <h1 className="text-gray-500 text-2xl lg:text-3xl text-center ">
        Coming Soon...
      </h1> */}
      <SearchFilter
        value={searchInput}
        data={productData}
        renderResults={(results) => (
          <h1 className="grid grid-cols-2 lg:grid-cols-3">
            {results.map((item, i) => (
              <ProductCard data={item} key={i} />
            ))}
          </h1>
        )}
      />
    </div>
  );
};

export default Homes;
